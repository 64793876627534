import React, { Component } from "react";
import { Stack, Card, Typography, TextField, Button } from "@mui/material";
import GoogleButton from "react-google-button";
import {
  continue_with_google,
  continuing_with_google,
} from "../../controllers/google.controller";
import { sendCode } from "../../controllers/telegram.controller";

export default class LoginComponent extends Component {
  state: Readonly<{
    view: "google" | "otp" | "unauthorized";
    google: {
      btn: {
        disabled: boolean;
      };
    };
    user: {
      email: string;
      token: string;
    };
    code: number;
    otp: {
      value: string;
    };
  }>;
  props: Readonly<{}>;
  constructor(props: any) {
    super(props);
    this.props = props;
    this.state = {
      view: "google",
      google: {
        btn: {
          disabled: false,
        },
      },
      user: {
        email: "",
        token: "",
      },
      code: 0,
      otp: {
        value: "",
      },
    };
  }
  componentDidMount(): void {
    if (window.location.hash.indexOf("access_token") !== -1) {
      var access_token = window.location.hash.split("=")[1].split("&")[0];
      var google = this.state.google;
      google.btn.disabled = true;
      this.setState({ google });
      continuing_with_google()
        .then((data) => {
          console.log(data);

          if(data.isAdmin==true){
            sendCode(data.user.mail)
            .then((code) => {
              data["accesstype"]="admin"
              data["email"]=data.user.mail
              var user = { ...data, access_token };
              this.setState({ user, view: "otp", code });
            })
            .catch((e) => {
              console.log(e);
              this.setState({ view: e });
            });
          }else{
            console.warn("surya");
            console.log(data);

            if(data.user[4].includes("pending...")){
              this.setState({ view: "unauthorized" });
            }else{

              var userdata = {
                "email":data.user[2],
                "name":data.user[1],
                "photo":data.user[3],
                "status":data.user[4],
                "uid":data.user[0],
                "aprovedby":data.user[5],
                "accesstype":"dealers"
              }

              var user = { ...userdata, access_token };
              console.log(user);
              
              process.env.NODE_ENV === "development"
                  ? window.localStorage.setItem(
                      "tk",
                      JSON.stringify(user)
                    )
                  : window.sessionStorage.setItem(
                      "tk",
                      JSON.stringify(user)
                    );

                window.location.href = "/";

            }
            
            
          }
         
        })
        .catch((e) => {
          console.log(e);
          this.setState({ view: e });
        });
    }
  }
  render() {
    var { view, google } = this.state;
    if (view === "google") {
      return (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ marginTop: "20vh" }}
        >
          <Stack>
            <Card sx={{ maxWidth: 600, width: 400 }} variant="outlined">
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ margin: "2.5rem" }}
                spacing={2}
              >
                {/* <img src={MyIcon} alt="logo" width="100" height="100" /> */}
                <Typography variant="h2">Agrikraft</Typography>
                <GoogleButton
                  onClick={() => {
                    var google = this.state.google;
                    google.btn.disabled = true;
                    this.setState({ google });
                    continue_with_google();
                  }}
                  label={
                    google.btn.disabled
                      ? "logging you in..."
                      : "Sign in with Google"
                  }
                  disabled={google.btn.disabled}
                  type="dark"
                />
              </Stack>
            </Card>
          </Stack>
        </Stack>
      );
    } else if (view === "otp") {
      return (
        <div>
          <TextField
            label="OTP"
            variant="outlined"
            value={this.state.otp.value}
            onChange={(e) => {
              var otp = this.state.otp;
              otp.value = e.target.value;
              this.setState({ otp });
            }}
            size="small"
          />
          <Button
            variant="outlined"
            onClick={() => {
              var otp = this.state.otp;              
              if (otp.value === this.state.code.toString()) {
                console.log(this.state.user);
                process.env.NODE_ENV === "development"
                  ? window.localStorage.setItem(
                      "tk",
                      JSON.stringify(this.state.user)
                    )
                  : window.sessionStorage.setItem(
                      "tk",
                      JSON.stringify(this.state.user)
                    );

                window.location.href = "/";
              } else {
                alert("wrong otp");
              }
            }}
          >
            Verify
          </Button>
        </div>
      );
    } else if (view === "unauthorized") {
      return <>Unauthorized! </>;
    } else {
      return <>Something went wrong</>;
    }
  }
}
