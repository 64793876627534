import { ApiUrl } from "../configs/ApiUrl";
import { client_id, scopes } from "../configs/google";
import axios from "axios";
export function continue_with_google() {
  var redirect_uri = window.location.href;

  var finalUrl =
    "https://accounts.google.com/signin/oauth?client_id=" +
    client_id +
    "&response_type=token&scope=" +
    scopes.join(" ") +
    "&redirect_uri=" +
    redirect_uri +
    "&prompt=consent";
  window.location.href = finalUrl;
}

export function continuing_with_google() {
  return new Promise<any>((resolve, reject) => {
    var access_token = window.location.hash
      .split("access_token=")[1]
      .split("&")[0];
    var config = {
      method: "get",
      url: "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
      headers: {
        Authorization: "Bearer " + access_token,
      },
    };
    axios(config)
      .then(({ data }) => data)
      .then((data) => {
        console.log(data);
        
        isAdmin(data)
          .then((res) => {
             resolve(res.data);
            
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((err) => {
        console.error(err);
        alert(
          "Problem in fetching google user info! Click on OK to retry. if problem continues please contact us."
        );
        window.location.href = "/login";
      });
  });
}

export function isAdmin(data:any) {
  let config = {
    method: "get",
    url: ApiUrl + "?action=isAdmin&route=admin&email=" + data.email+"&photo="+data.picture+"&name="+data.name+"&uid="+data.id    ,
  };
  return axios(config);
}
