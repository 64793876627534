import axios from "axios";
import { TelegramUrl } from "../configs/telegram";

export function sendCode(email: string) {
  return new Promise<number>((resolve, reject) => {
    var code = generateCode(6);
    var fullUrl = TelegramUrl + "code for " + email + "to login is: " + code;
    
    if(process.env.NODE_ENV == "production"){
      axios(fullUrl)
        .then(() => {
          resolve(code);
        })
        .catch((e) => {
          reject(e);
        });

    }else{
      console.log("code otp: ",code);
      resolve(code)
    }
  });
}
function generateCode(length: number) {
  const min = Math.pow(10, length - 1);
  const max = Math.pow(10, length) - 1;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
